import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { HeroContainer, HeroContent, HeroWrapper, HeaderWrapper, HeroRow, Column1, Img, ImgWrap, Column2, Subheading, HeroPLink, HeaderH1, Topper } from './HeroElements';
import img from '../../media/Sab.jpg';

const HeroSection = () => {


    return (
        <HeroContainer id="home">
            <HeroContent>
                <HeroWrapper>
                    <HeroRow>
                        <Column1>
                            <ImgWrap>
                                <Img src={img} />
                            </ImgWrap>
                        </Column1>
                        <Column2 initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 2,
                                delay: 1,
                                ease: [0, 0.71, 0.2, 1.01]
                            }}>
                            <HeaderWrapper>
                                <Topper>
                                    Your Friendly Neighborhood Front-End Developer
                                </Topper>
                                <HeaderH1>
                                    Say Hello to Sabreen
                                </HeaderH1>
                                <Subheading>
                                    Hey there, I'm Sabreen, a self-taught Front-End Developer with a focus on design. I use my skills in CSS, Photoshop, and animation to craft user-friendly and visually appealing websites. Drawing inspiration from diverse sources such as grunge fashion, art, and music, I bring an avant-garde touch to my work. Being at the intersection of creativity and technology is where I thrive, and it's what I love most about my work.
                                </Subheading>
                                <HeroPLink to="aboutme">
                                    <AiOutlineArrowRight />
                                    Learn More
                                </HeroPLink>
                            </HeaderWrapper>
                        </Column2>
                    </HeroRow>
                </HeroWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection;