import styled from 'styled-components';


export const PrepareContainer = styled.div`
height:100%;
display:grid;
margin:auto;
place-content: center;
flex-direction:column;
justify-content:center;
align-items:center;
background:#0e1111;
max-width:100%;
padding-top:3rem;
padding-bottom:3rem;
position:relative;
 @media screen and (max-width:1440px){
    height:450px;
}
@media screen and (max-width:1024px){
    height:450px;
}
@media screen and (max-width: 768px){
    height:550px;
}
@media screen and (max-width:630px){
    height:900px;
}
@media screen and (max-width:425px){
    height:1000px;
}
@media screen and (max-width:375px){
    height:1000px;
}
@media screen and (max-width:320px){
    height:1050px;
}
`;



export const PrepareP = styled.p`
font-family: 'Source Sans Pro', sans-serif;
color:#999999;
font-size:1rem;
padding:1rem;
text-align:left;
margin:auto;
`;

export const PrepareWrapper = styled.div`
margin:0 auto;
max-width:1100px;
display:flex;
background: transparent;
justify-content:center;
align-items:center;
`;


export const PrepareMenu = styled.ul`
padding:1rem;
list-style:none;
text-align:center;
margin:0 auto;
display:table;
grid-template-columns: repeat(3, 1fr);
align-items:center;
grid-gap:2rem;
@media screen and (max-width:1024px){
display:table;
grid-template-columns:1fr 1fr 1fr;
align-items:center;
grid-gap:2rem;
}
@media screen and (max-width:768px){
display:table;
grid-template-columns: repeat(3, 1fr);
align-items:center;
grid-gap:2rem;
}
@media screen and (max-width:630px){
display:table;
grid-template-columns:1fr;
align-items:center;
grid-gap:2rem;
}
@media screen and (max-width:425px){
display:grid;
grid-template-columns:1fr;
align-items:center;
grid-gap:2rem;
}
@media screen and (max-width:375px){
display:grid;
grid-template-columns:1fr;
align-items:center;
grid-gap:2rem;
}
@media screen and (max-width:320px){
display:grid;
grid-template-columns:1fr;
align-items:center;
grid-gap:2rem;}
`;

export const PrepareSteps = styled.li`
padding:1rem;
transition: 0.2s ease-in-out;
display:table-cell;
`;

export const PrepareSub = styled.h6`
font-family: 'Roboto Condensed', sans-serif;
color:#f4f4f4;
text-transform:uppercase;
`;

export const PrepareHeader = styled.h3`
font-family: 'Roboto Condensed', sans-serif;
color:#870000;
text-align:center;
text-transform:capitalize;
`;

