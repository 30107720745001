import React from 'react';
import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, ImgWrap, Img, AboutH1, AboutP, TextWrapper, BtnWrapper, Button, TopLine, Skills, SkillsMenu, Bar } from './AboutElements'
import img from '../../media/S6.jpg';
import { AiOutlineArrowRight } from 'react-icons/ai';
import './About.css';


const About = () => {
    return (
        <AboutContainer id="about">
            <AboutWrapper>
                <AboutRow>
                    <Column1>
                        <ImgWrap>
                            <Img src={img} initial={{ opacity: 0, scale: 1 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    duration: 2,
                                    delay: 0.5,
                                    ease: [0, 0.71, 0.2, 1.01]
                                }} />
                        </ImgWrap>
                        <TextWrapper initial={{ opacity: 0, scale: 1 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 2,
                                delay: 0.5,
                                ease: [0, 0.71, 0.2, 1.01]
                            }} >
                            <TopLine>
                                Professional Skills
                            </TopLine>
                            <SkillsMenu>
                                <Skills>
                                    HTML/CSS
                                </Skills>
                                <Bar className="html">
                                    95%
                                </Bar>
                                <Skills>
                                    Figma
                                </Skills>
                                <Bar className="figma">
                                    80%
                                </Bar>
                                <Skills>
                                    Photoshop
                                </Skills>
                                <Bar className="photoshop">
                                    80%
                                </Bar>
                                <Skills>
                                    JavaScript
                                </Skills>
                                <Bar className="javascript">
                                    90%
                                </Bar>
                                <Skills>
                                    Git
                                </Skills>
                                <Bar className="git">
                                    95%
                                </Bar>
                                <Skills>
                                    ReactJS
                                </Skills>
                                <Bar className="reactjs">
                                    92%
                                </Bar>
                            </SkillsMenu>
                        </TextWrapper>
                    </Column1>
                    <Column2
                        initial={{
                            x: -250,
                        }}
                        animate={{
                            x: -10,
                        }}
                        transition={{ ease: "easeOut", duration: 1.5 }}>
                        <TopLine>
                            Crafting Unique and Enjoyable Web Experiences:
                        </TopLine>
                        <AboutH1>
                            A Front-End Developer's Approach
                        </AboutH1>
                        <TextWrapper>
                            <AboutP>
                                Hi, I'm Sabreen, a Front-End Developer who is passionate about creating user-friendly and visually appealing websites. My expertise lies in using CSS, Photoshop, and animation to create designs that cater to the needs of different users. I work closely with teams and businesses to deliver custom web experiences that are not only technically sound but also unique and enjoyable. Apart from coding and keeping up with new technologies, you can find me nurturing my houseplants or playing video games.
                            </AboutP>

                        </TextWrapper>
                    </Column2>
                </AboutRow>
                <BtnWrapper>
                    <Button to='/' onClick="home">
                        head back
                        <AiOutlineArrowRight />
                    </Button>
                </BtnWrapper>
            </AboutWrapper>
        </AboutContainer>
    )
}

export default About;