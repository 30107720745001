export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    priceText: false,
    priceLine: "2 week turn-around:$3000 CAD",
    topText: true,
    topLine: "The artist",
    headline: "The Architect",
    description: "A specially curated experience. You’re not selling a product, you're selling an experience. You provide a service that isn't as simple as putting a price tag on a product.",
    experienceLines: [
        "5 page Website; include home,about,offers,work, and contact.",
        "Branding: custom fonts, color palete, and IG highlights",
        "Prepared checklists + streamlined communication",
        "Website training with private support + how-to content"
    ],
    imgStart: true,
    img: require('../../media/artist.jpg'),
    alt: 'Pexels Image by Wendy Wei',
    dark: false,
    primary: false,
    darkText: false,
};

export const homeObjTwo = {
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    priceText: false,
    priceLine: "1 week turn-around:$1500 CAD",
    topText: true,
    topLine: "Branding package",
    headline: "Brand Refresh",
    description: "Nothing lasts forever. That applies to branding too. You're most likely here to get all the aesthetics without having to build a whole new website. I got you!",
    experienceLines: [
        "Creating a new “personality” for your brand: replacing images, background, custom fonts and color palete.",
        "Prepared checklists + streamlined communication",
        "Website testing: making sure your website is running to its highest capabilities"
    ],
    imgStart: false,
    img: require('../../media/branding.jpg'),
    alt: 'Pexels Image by Thiago Miranda',
    dark: false,
    primary: true,
    darkText: false,
};


export const homeObjThree = {
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    priceText: false,
    priceLine: "Turn-around varies based on the project:$5000-$10,000 CAD",
    topText: true,
    topLine: "E-commerce Store",
    headline: "The Empire",
    description: "The Empire is designed to help you build a one-of-a-kind e-commerce store that reflects your brand. Build an elevated online empire that sets you apart from the competition and drives success for your business.",
    experienceLines: [
        "10+ page Website; 2-3 sales pages, products/shop +more",
        "Branding: custom fonts, color palete, and IG highlights",
        "Prepared checklists + streamlined communication",
        "Website training with private support + how-to content"
    ],
    imgStart: true,
    img: require('../../media/empire.jpg'),
    alt: 'Pexels Image by Albin Biju',
    dark: false,
    primary: false,
    darkText: false,
};