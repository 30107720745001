import React from 'react';
import { DataContainer, DataWrapper, DataRow, Column1, TextWrapper, TopLine, PriceLine, Heading, Subtitle, Img, ImgWrap, Column2, Experience, ExperienceLine } from './DataElements';

const DataSection = ({ lightBg, imgStart, topLine, experienceLines, topText, priceLine, lightText, headline, darkText, description, img, alt, }) => {
    return (
        <>

            <DataContainer lightBg={lightBg}>
                <DataWrapper>
                    <DataRow imgStart={imgStart}>
                        <Column1>
                            <ImgWrap>
                                <PriceLine initial={{ y: -50, opacity: 0 }}
                                    animate={{ y: -10, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 1.5, delay: 1 }}>
                                    {priceLine}
                                </PriceLine>
                                <Img initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 2,
                                        delay: 1.5,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    }}
                                    src={img} alt={alt} />
                            </ImgWrap>
                        </Column1>
                        <Column2>
                            <TextWrapper initial={{ y: -50, opacity: 0 }}
                                animate={{ y: -10, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: 1.5, delay: 1 }}>
                                <TopLine topText={topText}>
                                    {topLine}
                                </TopLine>
                                <Heading lightText={lightText}>
                                    {headline}
                                </Heading>
                            </TextWrapper>
                            <TextWrapper>
                                <Subtitle darkText={darkText}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 2,
                                        delay: 1.2,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    }}>
                                    {description}
                                </Subtitle>
                                <Experience initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 2,
                                        delay: 1,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    }}>
                                    What it includes :
                                    <br /> <br />
                                    {experienceLines && experienceLines.map((line, index) => (
                                        <ExperienceLine key={index}>{line}</ExperienceLine>
                                    ))}
                                </Experience>
                            </TextWrapper>
                        </Column2>
                    </DataRow>
                </DataWrapper>
            </DataContainer>
        </>
    )
}

export default DataSection;