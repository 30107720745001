import styled from 'styled-components';
import { motion } from "framer-motion";


export const WorkContainer = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
padding: 0 30px;
height:500px;
position:relative;
place-content: center;
width:100%;
z-index:1;
padding-top:5rem;

@media screen and (max-width:768px){
    padding:100px 0;
    padding-bottom:2rem;
    height:500px;
}
@media screen and (max-width:580px){
     padding:100px 0;
     padding-bottom:5rem;
     height:700px;
}
@media screen and (max-width:375px){
     padding:100px 0;
     padding-bottom:5rem;
     height:700px;
}
@media screen and (max-width:320px){
     padding:100px 0;
     padding-bottom:5rem;
     height:700px;
}
`
    ;

export const WorkBg = styled.div`
position:absolute;
top:0;
right:0;
bottom:0;
left:0;
width:100%;
height:100%;
overflow:hidden;
`
export const VideoBg = styled.video`
width:100%
height:100%
-o- object-fit: cover;
object-fit: cover;
filter:brightness(65%);
`;


export const WHeader = styled(motion.h1)`
font-weight:700;
font-size:3.5rem;
text-align: center;
line-height: 1.1;
letter-spacing: 1.2px;
color:#870000;
box-decoration-break: clone;
text-shadow: 2px 2px 6px #000;
text-transform:uppercase;
font-family: 'Nothing You Could Do', cursive;
padding:1.5rem;

@media screen and (max-width:768px){
    font-size:3.5rem;
}
@media screen and (max-width:480px){
    font-size:2.5rem;
}
@media screen and (max-width:375px){
    font-size:2.5rem;
}
@media screen and (max-width:320px){
     font-size:2.5rem;
}
`
    ;

export const Subheader = styled(motion.p)`
font-family: 'Roboto Condensed', sans-serif;
text-align:center;
font-size:1rem;
color:#f4f4f4;
color:#fff;
font-size:smaller;
max-width:600px;
padding:1.5rem;
`
    ;


export const TextWrapper = styled.div`
z-index:3;
max-width:100%;
position:absolute;
display:flex;
flex-direction:column;
align-items:center;
`
    ;

