import styled from 'styled-components';
import { motion } from "framer-motion";
import { Link as LinkR } from 'react-router-dom';

export const AboutContainer = styled.div`
display: grid;
place-content: center;
justify-content:center;
align-items:center;
margin: 0 0 10px 0;
height:100%;
max-width:100%;
background:#131313;
padding:100px 0;

@media screen and (max-width:768px){
    padding:100px 0;
    padding-bottom:2rem;
    padding-left:60px;
}
@media screen and (max-width:480px){
     padding:100px 0;
     padding-bottom:5rem;
}
`
    ;

export const AboutWrapper = styled.div`
display:grid;
z-index:1;
width:100%;
height:100%;
max-width:1100px;
margin-right:auto;
margin-left:auto;
padding: 0 24px;
justify-content:center;
`
    ;


export const AboutRow = styled.div`
display:grid;
grid-auto-columns:minmax(auto,1fr);
align-items:center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
@media screen and (max-width:768px){
    grid-template-areas:${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
}
`
    ;

export const Column1 = styled.div`
margin-bottom:15px;
padding:0 20px;
grid-area:col1;
`
    ;
export const Column2 = styled(motion.div)`
margin-bottom:15px;
padding:0 60px;
grid-area:col2;
margin:auto;
`
    ;



export const AboutH1 = styled(motion.h1)`
font-weight:bold 700;
letter-spacing:1.4px;
text-transform:uppercase;
font-family: 'Roboto Condensed', sans-serif;
font-size:1.5rem;
text-align: left;
font-family: 'Source Sans Pro', sans-serif;
background: #ff0000;  
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-shadow:none;


@media screen and (max-width:768px){
     font-size:1.8rem;
}
@media screen and (max-width:480px){
    font-size:1.5rem;
}
@media screen and (max-width:375px){
    font-size:1.5rem;
}
@media screen and (max-width:320px){
     font-size:1.5rem;
}


`
    ;

export const ImgWrap = styled.div`
max-width:40rem;
height:100%;
padding:2.5rem;
`
    ;

export const Img = styled(motion.img)`
display: flex;
overflow: hidden;
width:24rem;
height: 100%;
max-width: 100%;
padding-right: 0;
background:transparent;
@media screen and(max-width: 1440px){
    margin: auto;
    align-items: center;
    padding-left:1rem;
    width:24rem;
    height:auto;
}
@media screen and(max-width: 768px){
    margin: auto;
    align-items: center;
    width:30rem;
    height:auto;
}
@media screen and(max-width: 480px){
    margin:auto;
    align-items:right;
    width:35rem;
    height: auto;
}
@media screen and(max-width: 375px){
    margin:auto;
    align-items:right;
    width:35rem;
    height: auto;
}
@media screen and(max-width: 320px){
    margin:auto;
    align-items:right;
    width:35rem;
    height: auto;
}
`

export const AboutP = styled.p`
margin:left;
font-weight: 500;
font-size: 1rem;
text-align:left;
font-family: 'Source Sans Pro', sans-serif;
color:#f4f4f4;


@media screen and(max-width: 768px){
    text-align:right;
    right:auto;
}
@media screen and(max-width: 480px){
    right:auto;
    text-align:right;

}
`;

export const TextWrapper = styled(motion.div)`
max-width:540px;
padding-bottom: 60px;
`
    ;

export const BtnWrapper = styled.div`
margin-top: 32px;
display:flex;
flex-direction: column;
align-items:center;
padding-bottom:1.5rem;
`;



export const Button = styled(LinkR)`
position:relative;
box-sizing:border-box;
display:inline-block;
text-align:right;
color:#f4f4f4;
white-space:nowrap;
font-family: 'Roboto Condensed', sans-serif;
background:#131313;
text-transform:lowercase;
border-radius:20px;
font-size:16px;
text-decoration:none;
padding:16px 36px;
cursor:pointer;
border:none;
box-shadow: 0 0 5px #870000, 0 0 25px #870000, 0 0 50px #870000, 0 0 50px #870000;
:hover {
transition: 0.2s ease-in-out;
color:#f4f4f4;
text-shadow:none;
transform:scale(1.1);
}
`;

export const TopLine = styled.p`
font-size:1.1rem;
line-height:16px;
font-weight:bold 700;
letter-spacing:1.4px;
text-transform:capitalize;
font-family: 'Roboto Condensed', sans-serif;
color:#f4f4f4;
text-shadow:none;
`;

export const SkillsMenu = styled.ul`
display:grid;
grid-template-columns:1fr 1fr;
text-align:left;
justify-content:center;
box-sizing: border-box;
list-style:outside;
list-style-type:none;
padding:1rem;
font-style:italic;
background:#131313;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const Skills = styled.li`
text-align:left;
justify-content:center;
font-family: 'Source Sans Pro', sans-serif;
color:#f4f4f4;
line-spacing:1.5rem;

`;

export const Bar = styled.div`
background:#ff0000;
display:block;
height:20px;
border-radius:10px;
overflow:hidden;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
transition:all 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.1);
:hover {
transition: 0.2s ease-in-out;
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
text-shadow:none;
transform:scale(1.1);
}`;