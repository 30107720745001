import React from 'react';
import Promo from '../components/Promo';
import WorkHeader from '../components/WorkHeader';
import DataSection from '../components/DataSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/DataSection/Data';
import Prepare from '../components/Prepare';
import Contact from '../components/Contact/ContactForm';
import ScrollToTop from '../components/ScrollToTop';

const Hireme = () => {
    return (
        <>
            <ScrollToTop />
            <Promo />
            <WorkHeader />
            <Prepare />
            <DataSection {...homeObjOne} />
            <DataSection {...homeObjTwo} />
            <DataSection {...homeObjThree} />
            <Contact />
        </>
    )
}

export default Hireme;