import React from 'react';
import { PrepareContainer, PrepareP, PrepareWrapper, PrepareMenu, PrepareSteps, PrepareSub, PrepareHeader } from './PrepareElements'


const Prepare = () => {

    return (
        <PrepareContainer>
            <PrepareWrapper>
                <PrepareMenu>
                    <PrepareSteps>
                        <PrepareSub>
                            NO.1
                        </PrepareSub>
                        <PrepareHeader>
                            Get Ready
                        </PrepareHeader>
                        <PrepareP>
                            Before we get started on the production process, you will need to complete the form to collect necessary details regarding the project. The objective is to ensure that your experience is smooth and productive.
                        </PrepareP>
                    </PrepareSteps>
                    <PrepareSteps>
                        <PrepareSub>
                            NO.2
                        </PrepareSub>
                        <PrepareHeader>
                            The Vision
                        </PrepareHeader>
                        <PrepareP>
                            To start off, we'll have a strategy call to ensure that your website is not only designed to convert sales  but also aligns with your vision. Afterwards, I'll apply my design expertise to bring your vision to life.
                        </PrepareP>
                    </PrepareSteps>
                    <PrepareSteps>
                        <PrepareSub>
                            NO.3
                        </PrepareSub>
                        <PrepareHeader>
                            Launch
                        </PrepareHeader>
                        <PrepareP>
                            After I complete design revisions, we will be prepared for launch. Post launch, you will be provided with instructional materials and support to help you manage your website in the future. If you need any updates to the layout at a later time, please don't hesitate to contact me.                        </PrepareP>
                    </PrepareSteps>
                </PrepareMenu>
            </PrepareWrapper>
        </PrepareContainer>
    )
}

export default Prepare