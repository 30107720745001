import React from 'react';
import { WorkContainer, WHeader, Subheader, TextWrapper, WorkBg, VideoBg } from './WorkElements';
import Video from '../../media/smoke.mp4';

const WorkHeader = () => {
    return (
        <WorkContainer>
            <WorkBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </WorkBg>
            <TextWrapper>
                <WHeader initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 2,
                        delay: 0.4,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    Closed Mouths
                    Don't get fed
                </WHeader>
                <Subheader initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 2,
                        delay: 1,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    Want to stand out from your competitors? Stop chasing clients, attract them with a uniquely distinct experience. Start feeding your business.
                </Subheader>
            </TextWrapper>
        </WorkContainer>
    )
}

export default WorkHeader;