import React from 'react';
import Nav from '../components/Nav';
import About from '../components/About';
import ScrollToTop from '../components/ScrollToTop';

const Aboutme = () => {
    return (
        <>
            <ScrollToTop />
            <Nav />
            <About />
        </>
    )
};

export default Aboutme;